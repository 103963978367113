import '../styles/Contact.css';
import rightArrow from '../assets/right-arrow.png';
import Snippet from '../components/Snippet';
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';

const updateLabelOnFocus = (e) => {
    const label = document.querySelectorAll('.input-label');
    const input = e.target;

    label.forEach((item) => {
        if (item.htmlFor === input.id) {
            item.classList.add('input-label-active');
            item.classList.add('input-label-focus');
        }
    });
};

const updateLabelOnBlur = (e) => {
    const label = document.querySelectorAll('.input-label');
    const input = e.target;

    if (input.value === '') {
        label.forEach((item) => {
            if (item.htmlFor === input.id) {
                item.classList.remove('input-label-active');
            }
        });
    }
    label.forEach((item) => {
        if (item.htmlFor === input.id) {
            item.classList.remove('input-label-focus');
        }
    });
};

const Contact = () => {
    const [alert, setAlert] = useState(true);
    useEffect(() => {
        const form = document.getElementById('contact-form');
        const inputs = form.querySelectorAll('input, textarea');
        const submitButton = form.querySelector('button');

        const validateForm = () => {
            const email = form.querySelector('input[type="email"]');
            const message = form.querySelector('textarea');

            if (email.validity.valid && message.value !== '') {
                submitButton.disabled = false;
                submitButton.classList.add('submit-enabled');
                submitButton.classList.remove('submit-disabled');
            } else {
                submitButton.disabled = true;
                submitButton.classList.remove('submit-enabled');
                submitButton.classList.add('submit-disabled');
            }
        };

        const alertText = document.querySelector('.alert-text');

        const handleSubmit = (e) => {
            e.preventDefault();

            const name = form.querySelector('input[name="name"]').value;
            const email = form.querySelector('input[name="email"]').value;
            const message = form.querySelector('textarea[name=message]').value;

            axios.post("https://gomiusback-production.up.railway.app/contact", { name, email, message })
                .then((response) => {
                    setAlert(true);

                    alertText.textContent = "¡Gracias por tu mensaje! Me pondré en contacto contigo lo antes posible.";
                    alertText.classList.add('alert-success');

                    setTimeout(() => {
                        alertText.classList.remove('alert-success');
                        alertText.classList.add('alert-fade');
                    }, 5000);
                    setTimeout(() => {
                        setAlert(false);
                    }, 6000);
                })
                .catch((error) => {
                    setAlert(true);
                    alertText.classList.add('alert-error');
                    alertText.textContent = "¡Oh no! Algo salió mal. Por favor, inténtalo de nuevo más tarde.";

                    setTimeout(() => {
                        alertText.classList.remove('alert-error');
                        alertText.classList.add('alert-fade');
                    }, 5000);
                    setTimeout(() => {
                        setAlert(false);
                    }, 6000);
                });

            setTimeout(() => {
                form.reset();
                submitButton.disabled = true;
            }, 500);
        };

        inputs.forEach((input) => {
            input.addEventListener('input', validateForm);
        });

        form.addEventListener('submit', handleSubmit);

        return () => {
            inputs.forEach((input) => {
                input.removeEventListener('input', validateForm);
            });

            form.removeEventListener('submit', handleSubmit);
        };
    }, []);

    return (
        <main>
            <section id='contact-section'>
                <article id='contact-article'>
                    <div id='contact-form-info' className='contact-form-item'>
                        <h1>¡Colaboremos!</h1>
                        <p>Si tienes alguna idea en mente, un proyecto en el que necesitas ayuda o simplemente quieres saludar, no dudes en ponerte en contacto conmigo.</p>
                        <Snippet />
                    </div>
                    <form id='contact-form' className='contact-form-item' method='POST'>
                        <div className='form-input'>
                            <label className='input-label' htmlFor='nameInput'>Nombre</label>
                            <input type='text' id='nameInput' name='name' onBlur={updateLabelOnBlur} onFocus={updateLabelOnFocus} />
                        </div>
                        <div className='form-input'>
                            <label className='input-label' htmlFor='emailInput'>Correo Electrónico *</label>
                            <input type='email' id='emailInput' name='email' onBlur={updateLabelOnBlur} onFocus={updateLabelOnFocus} required />
                        </div>
                        <div className='form-input'>
                            <label className='input-label' htmlFor='messageInput'>Mensaje *</label>
                            <textarea name='message' id='messageInput' onBlur={updateLabelOnBlur} onFocus={updateLabelOnFocus}></textarea>
                        </div>
                        <button className='input-submit' type='submit' disabled>Enviar <img src={rightArrow} alt='Right arrow'></img></button>
                    </form>
                </article>
            </section>
            {alert && <div className='alert'>
                        <p className='alert-text'></p>
                        </div>}
            <Footer />
        </main>
    );
};

export default Contact;