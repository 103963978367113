import '../styles/Card.css';
import { useState, useEffect, useRef } from 'react';

const Card = ({ title, image, percentage, details }) => {
    const [clicked, setClicked] = useState(false);
    const cardRef = useRef(null);

    let backgroundColor;
    if(percentage < 50) {
        backgroundColor = 'red';
    } else if ((percentage >= 50) && (percentage < 75)) {
        backgroundColor = '#d6da1a';
    }
    else if(percentage >= 75){
        backgroundColor = '#12b995';
    }

    useEffect(() => {
        const card = cardRef.current;

        const handleClick = () => {
            setClicked(prevState => !prevState);
        };

        card.addEventListener('click', handleClick);

        return () => {
            card.removeEventListener('click', handleClick);
        };
    }, [clicked]);

    return (
        <div className="card" ref={cardRef}>
            <img src={image} alt={title} />
            <h2>{title}</h2>
            <div className="percentage-bar">
                <div style={{ width: `${percentage}%`, backgroundColor }}></div>
            </div>
            {clicked  && 
                <div className="card-details">
                    <h2>{title}</h2>
                    <ul>
                        {details.map((detail) => (
                            <li key={detail.id}>{detail.name}</li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    );
};

export default Card;