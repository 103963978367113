import '../styles/Navbar.css'
import logoWhite from '../assets/logo-white.png';
import logoDark from '../assets/logo-dark.png';
import { HashLink } from 'react-router-hash-link'; 
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const navbarRef = useRef(null);
    const burger = useRef(null);
    const [logoScrolled, setlogoScrolled] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);
    const location = useLocation();

    useEffect(() => { // changes color depending on page
        if(location.pathname !== '/') {
            setlogoScrolled(true);
            const navbar = navbarRef.current;
            navbar.classList.add('navbar-background-desktop');

            const burgerMenu = burger.current;
            burgerMenu.style.color = '#000';

            const navbarLinks = document.querySelectorAll('.navbar-list-item-desktop');
            navbarLinks.forEach(link => {
                link.classList.add('navbar-background-item');
            });
        } else {
            setlogoScrolled(false);
            const navbar = navbarRef.current;
            navbar.classList.remove('navbar-background-desktop');

            const burgerMenu = burger.current;
            burgerMenu.style.color = '#fff';

            const navbarLinks = document.querySelectorAll('.navbar-list-item-desktop');
            navbarLinks.forEach(link => {
                link.classList.remove('navbar-background-item');
            });
        }
    }, [location.pathname]);

    useEffect(() => { // changes color depending on page and scroll
        const navbar = navbarRef.current;
        const burgerMenu = burger.current;
        const windowScroll = () => {
            const navbarLinks = document.querySelectorAll('.navbar-list-item-desktop');

            if(location.pathname === '/') {
                if(window.scrollY > 0) {
                    setlogoScrolled(true);
                    navbar.classList.add('navbar-background-desktop');
                    burgerMenu.style.color = '#000';
                
                    navbarLinks.forEach(link => {
                        link.classList.add('navbar-background-item');
                    });
                } else {
                    if(!toggleMenu) {
                        setlogoScrolled(false);
                        navbar.classList.remove('navbar-background-desktop');
                        burgerMenu.style.color = '#fff';
                    }
                    
                    navbarLinks.forEach(link => {
                        link.classList.remove('navbar-background-item');
                    });
                }
            }
        };

        window.addEventListener('scroll', windowScroll);

        return () => {
            window.removeEventListener('scroll', windowScroll);
        };
    }, [location.pathname, toggleMenu]);

    useEffect(() => { // burger menu color depending on page
        const burgerMenu = burger.current;
        setToggleMenu(false);
        if(location.pathname !== '/') {
            burgerMenu.style.color = '#000';
            
        } else {
            burgerMenu.style.color = '#fff';
        }
    }, [location.pathname]);

    useEffect(() => { // toggle mobile menu
        const burgerMenu = burger.current;

        const toggleMenuMobile = () => {
            setToggleMenu(prevState => !prevState);
        };

        burgerMenu.addEventListener('click', toggleMenuMobile);

        return () => {
            burgerMenu.removeEventListener('click', toggleMenuMobile);
        };
    }, []);

    useEffect(() => { // keep navbar background class when menu toggled on mobile
        const navbar = navbarRef.current;
        const burgerMenu = burger.current;
        
        if(toggleMenu) {
            setlogoScrolled(true);
            navbar.classList.add('navbar-background-desktop');
            burgerMenu.style.color = '#000';
        } else {
            if(location.pathname === '/' && window.scrollY === 0) {
                navbar.classList.remove('navbar-background-desktop');
                setlogoScrolled(false);
                burgerMenu.style.color = '#fff';
            } else if(location.pathname === '/' && window.scrollY > 0) {
                navbar.classList.add('navbar-background-desktop');
                burgerMenu.style.color = '#000';
                setlogoScrolled(true);
            }
        }

    }, [toggleMenu, location.pathname]);

    useEffect(() => { // remove mobile menu when clicking somewhere else than the header
        const burgerMenu = burger.current;
        const navbar = navbarRef.current;
        const menu = document.getElementById('navbar-menu-mobile');
        const listMobile = document.getElementById('navbar-list-mobile');
        const closeMenu = (e) => {
            if(toggleMenu && e.target !== burgerMenu && e.target !== navbar && e.target !== menu && e.target !== listMobile) {
                setToggleMenu(false);
            }
        };

        document.addEventListener('click', closeMenu);

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [toggleMenu]);

    return (
        <header>
            <nav id="navbar-desktop" ref={navbarRef}>
                {logoScrolled && <HashLink className='link-logo-navbar' to="/#landing-section"><img className='logo' src={logoDark} alt="Logo" /></HashLink>}
                {!logoScrolled && <HashLink className='link-logo-navbar' to="/#landing-section"><img className='logo' src={logoWhite} alt="Logo" /></HashLink>}
                <ul id='navbar-list-desktop'>
                    <li><HashLink className='navbar-list-item-desktop' to="/#landing-section">Inicio</HashLink></li>
                    <li><HashLink className='navbar-list-item-desktop' to="/#about-section">Sobre mí</HashLink></li>
                    <li><HashLink className='navbar-list-item-desktop' to="/#skills-section">Habilidades</HashLink></li>
                    <li><HashLink className='navbar-list-item-desktop' to="/#services-section">Servicios</HashLink></li>
                    <li><HashLink className='navbar-list-item-desktop' to="/resume#resume-section">CV</HashLink></li>
                    <li><HashLink className='navbar-list-item-desktop' to="/contact#contact-section">Contacto</HashLink></li>
                </ul>
                <p id='navbar-toggle-mobile' ref={burger}>&#9776;</p>
                {toggleMenu &&
                    <div id='navbar-menu-mobile'>
                        <ul id='navbar-list-mobile'>
                            <li><HashLink className='navbar-list-item-mobile' to="/#about-section">Sobre mí</HashLink></li>
                            <li><HashLink className='navbar-list-item-mobile' to="/#skills-section">Habilidades</HashLink></li>
                            <li><HashLink className='navbar-list-item-mobile' to="/#services-section">Servicios</HashLink></li>
                            <li><HashLink className='navbar-list-item-mobile' to="/resume#resume-section">CV</HashLink></li>
                            <li><HashLink className='navbar-list-item-mobile' to="/contact#contact-section">Contacto</HashLink></li>
                        </ul>
                    </div>
                }
            </nav>
        </header>
    );
};

export default Navbar;