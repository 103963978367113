import '../styles/Home.css';
import { feSkills, beSkills } from '../data/habilidades';
import { services } from '../data/services';
import { useRef, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import Card from '../components/Card';
import Snippet from '../components/Snippet';
import Service from '../components/Service';
import Footer from '../components/Footer';

const Home = () => {
    const snippetRef = useRef(null);
    const [snippetVisible, setSnippetVisible] = useState(false);

    useEffect(() => {
        const snippet = snippetRef.current;
        const observer = new IntersectionObserver(([entry]) => {
            setSnippetVisible(entry.isIntersecting);
        },
        { rootMargin: '0px' }
    );

    snippet && observer.observe(snippet);

    return () => snippet && observer.unobserve(snippet);
    }, []);

    return (
        <main>
            <section id="landing-section">
                <h1>Soy Sebastián Castañeda</h1>
                <h2>Desarrollador Web</h2>
            </section>
            <section id="about-section">
                <h2>Sobre mí</h2>
                <p>
                    ¡Hola! Soy Sebastián, un desarrollador web autodidacta con amplia experiencia en 
                    tecnologías frontend y backend. Conozco bastante sobre la correcta creación de 
                    sitios web usando <span className='underline'>HTML semántico, CSS y JavaScript 
                    moderno</span>. Además, tengo experiencia en el uso de la librería frontend
                    <span className='highlight'> React</span>, la cual es estándar en la industria. 
                    En cuanto a tecnologías backend, tengo experiencia en el uso de
                    <span className='highlight'> Node.js</span> y
                    <span className='highlight'> Express</span> para la creación de servidores y
                    <span className='highlight'> APIs REST</span>. También tengo conocimientos en el 
                    uso de bases de datos relacionales como <span className='highlight'>MySQL</span>,
                    <span className='highlight'> SQL Management Studio</span>, y
                    <span className='highlight'> PostgreSQL</span>. Soy alguien 
                    <span className='highlight-2'> cometido</span>,
                    <span className='highlight-2'> responsable</span>, y con
                    <span className='highlight-2'> comunicación efectiva</span>. Mi sueño es hacer 
                    crecer a los negocios con los que trabajo, chicos o grandes.
                </p>
                <div id='about-snippet-div' ref={snippetRef}>
                    {snippetVisible &&
                        <Snippet />
                    }
                </div>
            </section>
            <section id='skills-section'>
                <h2 id='skills-title'>Habilidades</h2>
                <h3 className='skills-subtitles'>Frontend</h3>
                <article className='skills-article' id='frontend-article'>
                    {feSkills.map(skill => (
                        <Card
                            key={skill.id}
                            title={skill.name}
                            image={skill.image}
                            percentage={skill.percentage}
                            details={skill.details}
                        />
                    ))}
                </article>
                <h3 className='skills-subtitles'>Backend</h3>
                <article className='skills-article' id='backend-article'>
                    {beSkills.map(skill => (
                        <Card
                            key={skill.id}
                            title={skill.name}
                            image={skill.image}
                            percentage={skill.percentage}
                            details={skill.details}
                        />
                    ))}
                </article>
            </section>
            <section id='services-section'>
                <h2>Servicios</h2>
                <article id='services-article'>
                    {services.map(service => (
                        <Service
                            key={service.id}
                            name={service.name}
                            description={service.description}
                            image={service.image}
                        />
                    ))}
                </article>
                <p className='services-section-paragraph'>
                    Si necesitas un sitio web, una aplicación web, o una API REST, ¡no dudes en 
                    contactarme! Estoy dispuesto a trabajar contigo para hacer crecer tu negocio.
                    Entiendo que cada negocio es único, por lo que ofrezco soluciones personalizadas.
                    ¡Ponte en contacto conmigo y hablemos sobre cómo puedo ayudarte!
                </p>
                <HashLink className='button-link' to={"/contact#contact-section"}>Contáctame</HashLink>
            </section>
            <Footer />
        </main>
    );
};

export default Home;