export const feSkills = [
    {
        id: 1,
        name: 'HTML / CSS',
        image: require('../assets/html-css.png'),
        percentage: 90,
        details: [
            {
                id: 1,
                name: 'HTML semántico'
            },
            {
                id: 2,
                name: 'SEO'
            },
            {
                id: 3,
                name: 'CSS Grid'
            },
            {
                id: 4,
                name: 'Flexbox'
            },
            {
                id: 5,
                name: 'Diseño responsivo'
            }
        ]
    },
    {
        id: 2,
        name: 'JavaScript',
        image: require('../assets/js.png'),
        percentage: 75,
        details: [
            {
                id: 1,
                name: 'ES6+'
            },
            {
                id: 2,
                name: 'Programación orientada a objetos'
            },
            {
                id: 3,
                name: 'Programación funcional'
            },
            {
                id: 4,
                name: 'AJAX'
            },
            {
                id: 5,
                name: 'Fetch API'
            }
        ]
    },
    {
        id: 3,
        name: 'React',
        image: require('../assets/react.png'),
        percentage: 55,
        details: [
            {
                id: 1,
                name: 'Hooks'
            },
            {
                id: 2,
                name: 'Context API'
            },
            {
                id: 3,
                name: 'React Router'
            },
            {
                id: 4,
                name: 'Componentes estilizados'
            }
        ]
    }
];

export const beSkills = [
    {
        id: 1,
        name: 'Node.js',
        image: require('../assets/node.png'),
        percentage: 70,
        details: [
            {
                id: 1,
                name: 'NPM'
            },
            {
                id: 2,
                name: 'Validaciones'
            }
        ]
    },
    {
        id: 2,
        name: 'Express',
        image: require('../assets/express.png'),
        percentage: 70,
        details: [
            {
                id: 1,
                name: 'REST APIs'
            },
            {
                id: 2,
                name: 'Middlewares'
            }
        ]
    },
    {
        id: 3,
        name: 'SQL',
        image: require('../assets/sql.png'),
        percentage: 60,
        details: [
            {
                id: 1,
                name: 'Joins'
            },
            {
                id: 2,
                name: 'Subqueries'
            },
            {
                id: 3,
                name: 'Funciones'
            }
        ]
    }
];

