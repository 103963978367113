import '../styles/Resume.css';
import pdfEs from '../assets/Castañeda_Gomez_Sebastian_CV_ES.pdf';
import pdfEn from '../assets/Castañeda_Gomez_Sebastian_CV_EN.pdf';
import { cvES, cvEN } from '../data/cv';
import ResumeCard from '../components/ResumeCard';
import Footer from '../components/Footer';

const Resume = () => {
    return (
        <main>
            <section id="resume-section">
                <h1>Resume</h1>
                <div className='resume-container'>
                    <article id='resume-es' className='resume-card'>
                        <ResumeCard
                            key={cvES[0].id}
                            title={cvES[0].title}
                            image={cvES[0].image}
                            description={cvES[0].description}
                            buttonText={cvES[0].buttonText}
                            link={pdfEs}
                            target='_blank'
                            rel='noopener noreferrer'
                        />
                    </article>
                    <article id='resume-en' className='resume-card'>
                        <ResumeCard
                            key={cvEN[0].id}
                            title={cvEN[0].title}
                            image={cvEN[0].image}
                            description={cvEN[0].description}
                            buttonText={cvEN[0].buttonText}
                            link={pdfEn}
                            target='_blank'
                            rel='noopener noreferrer'
                        />
                    </article>
                </div>
            </section>
            <Footer />
        </main>
    );
};

export default Resume;