import '../styles/ResumeCard.css';

const ResumeCard = ({ title, description, link, target, rel, image, buttonText }) => {
    return (
        <>
            <a className='resume-image-link' href={link} target={target} rel={rel}><img src={image} alt={title} /></a>
            <h2>{title}</h2>
            <p>{description}</p>
            <a className='download-link' href={link} download>{buttonText}</a>
        </>
    );
};

export default ResumeCard;