export const services = [
    {
        id: 1,
        name: 'Portafolios',
        description: 'Creación de portafolios web para mostrar tus habilidades y proyectos para que asombres a clientes o posibles empleadores. Todos los portafolios son responsivos y se adaptan a cualquier dispositivo. También se pueden agregar animaciones y efectos para hacerlo más atractivo.',
        image: require('../assets/portfolio.jpg')
    },
    {
        id: 2,
        name: 'Sitios web',
        description: 'Creación de sitios web para tu negocio o empresa. Al igual que los portafolios, los sitios web son responsivos y además se pueden agregar formularios de contacto, entre otros elementos.',
        image: require('../assets/website.jpg')
    },
    {
        id: 3,
        name: 'Mantenimiento',
        description: 'Mantenimiento de tu sitio o aplicación web. Se realizan actualizaciones, corrección de errores, y se mantiene la estabilidad de tu sitio o aplicación web. También se pueden agregar nuevas funcionalidades o secciones a tu sitio o aplicación web.',
        image: require('../assets/maintenance.jpg')
    }
];