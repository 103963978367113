import '../styles/Snippet.css';

const Snippet = () => {
    return (
        <article className="code-terminal">
            <div className="code-snippet">
                <div className="snippet s-md"></div>
                <div className="snippet s-sm"></div>
                <div className="snippet s-lg"></div>
                <div className="snippet s-sm"></div>
            </div>
            <div className="code-snippet">
                <div className="snippet s-sm"></div>
                <div className="snippet s-md"></div>
                <div className="snippet s-md"></div>
            </div>
            {/* <h1>Sebastian Castaneda</h1> */}
            <div className="code-snippet">
                <div className="snippet s-md"></div>
                <div className="snippet s-md"></div>
                <div className="snippet s-sm"></div>
                <div className="snippet s-lg"></div>
                <div className="snippet s-sm"></div>
            </div>
            <div className="code-snippet">
                <div className="snippet s-lg"></div>
                <div className="snippet s-sm"></div>
                <div className="snippet s-md"></div>
            </div>
            <div className="code-snippet">
                <div className="snippet s-md"></div>
                <div className="snippet s-lg"></div>
                <div className="snippet s-sm"></div>
            </div>
        </article>
    );
};

export default Snippet;