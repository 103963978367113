export const cvES = [
    {
        id: 1,
        title: 'CV en Español',
        image: require('../assets/thumbnail_es.png'),
        description: 'Aquí puedes leer mi CV en Español.',
        buttonText: 'Descargar',
    }
];

export const cvEN = [
    {
        id: 1,
        title: 'CV in English',
        image: require('../assets/thumbnail_en.png'),
        description: 'Here you can read my CV in English.',
        buttonText: 'Download'
    }
];

