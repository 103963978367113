import '../styles/Service.css';

const Service = ({ name, description, image }) => {
    return (
        <div className="service-item">
            <div className="service-item-header">
                <div className="circle"></div>
                <h3>{name}</h3>
            </div>
            <div className="service-item-body">
                <p>{description}</p>
                <div className="service-item-image">
                    <img src={image} alt={name} />
                </div>
            </div>
            
        </div>
    );
};

export default Service;